import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createBuilder, select } from '@wix/ricos/internal';
import type { Node } from '@wix/ricos';
import { RichContent, getContentLength } from '@wix/ricos';
import { WidgetProps } from './types';

const createController: CreateControllerFn = async ({ controllerConfig, flowAPI }) => {
  // controller docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller

  const {
    environment: { isMobile, language },
  } = flowAPI;

  const { setProps, wixCodeApi, platformAPIs, appParams, compId } = controllerConfig;
  const { bi: { metaSiteId } = {} } = platformAPIs;
  const { instance } = appParams;
  let content: RichContent | null;

  const {
    seo: { isInSEO },
  } = wixCodeApi;
  return {
    async pageReady() {
      if ((controllerConfig.livePreviewOptions as any)?.compsIdsToReset?.[0] === compId) {
        content = null;
      }

      let props: WidgetProps = {
        locale: language,
        isMobile,
        seoSettings: isInSEO(),
        instance,
        metaSiteId,
        fitToContentHeight: true,
      };
      if ((controllerConfig.livePreviewOptions as any)?.compsIdsToReset?.[0] === compId) {
        props = { ...props, content: null };
      }
      return setProps(props);
    },

    exports() {
      const updateContent = (newContent: RichContent) => {
        content = newContent;
        setProps({ content });
      };
      const builder = () => createBuilder(content || { nodes: [] }, updateContent);
      return {
        type: '$w.RichContent',
        set value(newContent: RichContent | null) {
          content = newContent;
          setProps({
            content,
          });
        },

        get value() {
          return content;
        },

        select() {
          return select(content || { nodes: [] }, updateContent);
        },

        // builder functions
        append(node: Node) {
          return builder().append(node);
        },

        insertAfter(id: string, node: Node) {
          return builder().insertAfter(id, node);
        },

        insertBefore(id: string, node: Node) {
          return builder().insertBefore(id, node);
        },

        insertAt(index: number, node: Node) {
          return builder().insertAt(index, node);
        },

        getLength() {
          return content ? getContentLength(content) : 0;
        },

        getContent() {
          return content;
        },

        // get plainText() {
        //   return content ? api.toPlainText(content) : content;
        // },

        // fromMarkdown(markdown: string) {
        //   api.convertFromMarkdown(markdown).then(({ richContent }) => {
        //     content = richContent;
        //     setProps({
        //       content: toDraftContent(content),
        //     });
        //   });
        // },
      };
    },
  };
};

export default createController;
